//Valida E-mail
function IsEmail(email) {
    var exclude = /[^@\-\.\w]|^[_@\.\-]|[\._\-]{2}|[@\.]{2}|(@)[^@]*\1/;
    var check = /@[\w\-]+\./;
    var checkend = /\.[a-zA-Z]{2,3}$/;
    if (
        email.search(exclude) != -1 ||
        email.search(check) == -1 ||
        email.search(checkend) == -1
    ) {
        return false;
    } else {
        return true;
    }
}

//Envia E-mail Contato
$("#formContato").submit(function(e) {
    e.preventDefault();

    $("#btnEnviarContato").html(
        'Aguarde. Enviando.'
    );

    var nome = $("#nome").val();
    var email = $("#email").val();
    var telefone = $("#telefone").val();
    var mensagem = $("#mensagem").val();
    var erros = 0;

    if (nome == "") {
        $("#nome").addClass("errorField");
        erros++;
    } else {
        $("#nome").removeClass("errorField");
    }
    if (email == "" || !IsEmail(email)) {
        $("#email").addClass("errorField");
        erros++;
    } else {
        $("#email").removeClass("errorField");
    }
    if (telefone == "") {
        $("#telefone").addClass("errorField");
        erros++;
    } else {
        $("#telefone").removeClass("errorField");
    }
    if (mensagem == "") {
        $("#mensagem").addClass("errorField");
        erros++;
    } else {
        $("#mensagem").removeClass("errorField");
    }

    if (erros == 0) {
        $.post(
            url + "/email/form_contato.php",
            $("#formContato").serialize(),
            function(data) {
                if (data == "1") {
                    $(".erroContato").fadeOut("slow");
                    $("#formContato")[0].reset();
                    $(".sucessoContato").fadeIn("slow");
                    $("#btnEnviarContato").html("Enviar");
                }
            }
        );
    } else {
        $(".erroContato").fadeIn("slow");
        $("#btnEnviarContato").html("Enviar");
    }
});