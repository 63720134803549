$(window).on('scroll', function () {
    if ($(window).scrollTop() > 135) {
        $("header").addClass('active');
    } else if ($(window).scrollTop() < 135) {
        $("header").removeClass('active');
    }
});

$('#rollLinks').localScroll({
    target:'body',
    // offset: -97
});

$('#rollLinks2').localScroll({
    target:'body',
    // offset: -97
});

$('.botaoResponsivo').on('click', function(){
    $('.menu nav').toggleClass('ativo');
    $('.linhasmr').toggleClass('ativo');
    if ($('.linhasmr').hasClass('ativo')) {
        $(".linhasmr").addClass("ativo");
    } else {
        $(".linhasmr").removeClass("ativo");
    }
    return false;
});

$('#rollLinks li a').on('click', function(){
    $('#rollLinks li').removeClass('active');
    $('#rollLinks2 li').removeClass('active');
    $(this).closest('li').addClass('active');
});
$('#rollLinks2 li a').on('click', function(){
    $('#rollLinks li').removeClass('active');
    $('#rollLinks2 li').removeClass('active');
    $(this).closest('li').addClass('active');
});